@charset "UTF-8";

html, body {
    overflow-x: hidden;
    min-height: 100vh;
    width: 100%;
    padding: 0;
    margin: 0;
}
body {
  background-color: #FFF;
  color: #000;
  font-family: 'Mulish';
}

/* Desktop */
@media screen and (min-width: 1024px) {
    body {
        /* Not correct */
        /* background-color: #000; */
        /* color: #FFF; */
    }
    /* .hide-desktop {
        display: initial;
    }
    .hide-mobile {
        display: none;
    } */
}

main {
    min-height: 80vh;
}

#captcha-container {
    visibility: hidden;
}
button:disabled, input:disabled, textarea:disabled {
    /* background-color: grey; */
    opacity: 0.5;
}
.loading-page {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: #F0F0F0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.error-page {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: #FFF;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

